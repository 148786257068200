import Splide from '@splidejs/splide';
import SimpleLightbox from "simplelightbox";
import Cookies from 'js-cookie'
import { Tooltip } from 'bootstrap';

(function(){
    //Splide image banners
    let elms = document.querySelectorAll(".splide:not(.ms-dont-initialize)");

    for ( var i = 0; i < elms.length; i++ ) {
        new Splide( elms[ i ]).mount();
    }
    

    //Initialize Lightboxes for mobile phones if present
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if(vw < 768){
        if (typeof SimpleLightbox !== "undefined") { 
            new SimpleLightbox('.splide__list li img', { 
                uniqueImages: false,
                sourceAttr: "data-lightbox-src",
            });
        }
    }

    /* Visitor Source and phone number setting */
    //default phone numbers
    var phoneLink = "tel:00497951295331";
    var phoneDisplay = "07951-295331";
    var referrer = document.referrer;

    if(getParameterByName("clickref") == "localgeneric" || Cookies.get("clickref") === "localgeneric"){
        //visitor came from GAds
        Cookies.set('clickref', 'localgeneric');
        //var phoneLink = "tel:004979514879778";
        //var phoneDisplay = "07951-4879778";
    }else if((referrer.includes("google") && (window.location.pathname.includes("/blog-stadt/") || window.location.pathname.includes("/stadt/") || window.location.pathname.includes("/gemeinde/") || window.location.pathname.includes("/blog-bundesland/") )) || Cookies.get("clickref") === "organic"){
        //visitor came from google organic
        Cookies.set('clickref', 'organic');
        //var phoneLink = "tel:00497951295334";
        //var phoneDisplay = "07951-295334";
    }else if(referrer.includes("facebook") || Cookies.get("clickref") === "facebook"){
        Cookies.set('clickref', 'facebook');
    }

    //set utm parameters
    if(Cookies.get("ms_utm") !== "true" && !referrer.includes('grabstein-steinmetz.de')){
        //visitor is new on the site
        var ms_utm_source = referrer;
        var ms_utm_content = window.location.href;

        let clickref = getParameterByName("clickref");
        if(clickref !== null) ms_utm_source = clickref;

        
        Cookies.set("ms_utm", "true");
        Cookies.set("ms_utm_source", ms_utm_source);
        Cookies.set("ms_utm_content", ms_utm_content);
    }

    //set correct phone numbers except on kundenservice pages
    /*if(!window.location.pathname.includes("/kundenservice/")) {
        var phoneLinks = document.querySelectorAll('a[href^="tel:"]');
        var phoneSpans = document.querySelectorAll('.phonenumber');
        phoneLinks.forEach((phoneLinkEl) => {
            phoneLinkEl.href = phoneLink;
        });
        phoneSpans.forEach((phoneSpanEl) => {
            phoneSpanEl.innerHTML= phoneDisplay;
        }); 
    }*/

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /* TODO REMOVE WHEN NOT IN USE ANYMORE GLOBALLY */
    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    /* /TODO REMOVE WHEN NOT IN USE ANYMORE GLOBALLY */

})()